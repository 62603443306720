<div *ngIf="!loading; else loadingTemplate" class="pc-table">
  <div class="pc-table__header">
    <div (click)="sort()" class="pc-table__header__column pc-table__header__column__clickable">
      <div>{{ titleFirstCol }}</div>
      <div class="pc_table__header__icon">
        <app-icon color="var(--persian-green)" name="sort"></app-icon>
      </div>
    </div>
    <div class="pc-table__header__column">
      {{ titleSecondCol }}
    </div>
  </div>
  @for (item of items; track item) {
    <div class="pc-table__item">
      <div class="pc-table__item__column">
        {{ item.tableItemId }}
      </div>
      <div class="pc-table__item__column">
        {{ item.tableItemValue }}
      </div>
    </div>
  }
</div>

<ng-template #loadingTemplate>
  <div class="pc-table__loading-container">
    <div class="pc-table__loading-background">
      <div class="pc-table--loading">
        <div class="pc-table__header">
          <div class="pc-table__header__column--loading">loading content 1</div>
          <div class="pc-table__header__column--loading">loading content 2</div>
        </div>
        @for (i of [1, 2, 3]; track i) {
          <div class="pc-table__item--loading">
            <div class="pc-table__item__column--loading">loading value {{ i }}</div>
            <div class="pc-table__item__column--loading">loading second value {{ i }}</div>
          </div>
        }
      </div>
    </div>
  </div>
</ng-template>
